export function getDate(daysDifference) {
  let dateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'yyyy-mm-dd';
  /**
   * This recieves a date and returns an object which contains day, month, and year of that date as strings
   * @param {object} date
   * @returns {Object}
   */
  const getDateObject = date => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return {
      day,
      month,
      year
    };
  };
  /**
   * This calculates and returns a date given the days difference from current date
   * @param {Number} daysDifference
   * @param {String} dateFormat
   * @returns {String} date string in specidfied format
   */
  function getDateFromToday(daysDifference) {
    let dateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'yyyy-mm-dd';
    // Get today's date
    const today = new Date();

    // add daysDifference to current date
    const date = new Date(today);
    date.setDate(date.getDate() + daysDifference);
    const {
      day,
      month,
      year
    } = getDateObject(date);

    // Return the date string depending on specified format
    if ((dateFormat === null || dateFormat === void 0 ? void 0 : dateFormat.toLowerCase()) === 'dd.mm.yyyy') {
      return day + "." + month + "." + year;
    }
    if ((dateFormat === null || dateFormat === void 0 ? void 0 : dateFormat.toLowerCase()) === 'yyyy-mm-dd') {
      return year + "-" + month + "-" + day;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return getDateFromToday(daysDifference, dateFormat = 'yyyy-mm-dd');
}