import { DefaultDOIDomainConfig } from '@wkda/funnel-utils';
import { getDate } from './getDate';
export function getCustomDOIDomainConfig() {
  const SENDER = {
    'de-DE': 'info.wirkaufendeinauto.de',
    'de-AT': 'info.wirkaufendeinauto.at',
    'it-IT': 'info.noicompriamoauto.it'
  };

  /**
   * @type {import('@wkda/internal-types').IDOIDomains}
   */
  const CustomDOIDomainConfig = {
    ...DefaultDOIDomainConfig,
    'de-DE': {
      ...DefaultDOIDomainConfig['de-DE'],
      'gmail': {
        ...DefaultDOIDomainConfig['de-DE']['gmail'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://mail.google.com/mail/u/0/#search/from%3A" + SENDER['de-DE'] + "+after%3A" + getDate(-1) + "+in%3Aanywhere";
        }
      },
      'tonline': {
        ...DefaultDOIDomainConfig['de-DE']['tonline'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://email.t-online.de/em#method=inboxSearch&f=inbox&searchString=Von%3A" + SENDER['de-DE'] + "%3BAb%3AA" + getDate(-1, 'dd.mm.yyyy') + "%3BBis%3A" + getDate(0, 'dd.mm.yyyy');
        }
      },
      'livemail': {
        ...DefaultDOIDomainConfig['de-DE']['livemail'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://login.live.com";
        }
      },
      'yahoo': {
        ...DefaultDOIDomainConfig['de-DE']['yahoo'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://login.live.com";
        }
      },
      'freenet': {
        ...DefaultDOIDomainConfig['de-DE']['freenet'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://www.freenet.de/index.html";
        }
      },
      'gmx': {
        ...DefaultDOIDomainConfig['de-DE']['gmx'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "http://www.gmx.net";
        }
      },
      'web-de': {
        ...DefaultDOIDomainConfig['de-DE']['web-de'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "http://www.web.de/";
        }
      },
      'AOL': {
        ...DefaultDOIDomainConfig['de-DE']['AOL'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://mail.aol.com/d/search/keyword=from%253A" + SENDER['de-DE'] + "%2520after%253A%2522" + getDate(-1) + "%2522%2520before%253A%2522" + getDate(1) + "%2522";
        }
      }
    },
    'de-AT': {
      ...DefaultDOIDomainConfig['de-AT'],
      'gmail': {
        ...DefaultDOIDomainConfig['de-DE']['gmail'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://mail.google.com/mail/u/0/#search/from%3A" + SENDER['de-AT'] + "+after%3A" + getDate(-1) + "+in%3Aanywhere";
        }
      },
      'livemail': {
        ...DefaultDOIDomainConfig['de-DE']['livemail'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://login.live.com";
        }
      },
      'yahoo': {
        ...DefaultDOIDomainConfig['de-DE']['yahoo'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://login.live.com";
        }
      },
      'freenet': {
        ...DefaultDOIDomainConfig['de-DE']['freenet'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://www.freenet.de/index.html";
        }
      },
      'gmx': {
        ...DefaultDOIDomainConfig['de-DE']['gmx'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "http://www.gmx.net";
        }
      },
      'web-de': {
        ...DefaultDOIDomainConfig['de-DE']['web-de'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "http://www.web.de/";
        }
      },
      'AOL': {
        ...DefaultDOIDomainConfig['de-DE']['AOL'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://mail.aol.com/d/search/keyword=from%253A" + SENDER['de-AT'] + "%2520after%253A%2522" + getDate(-1) + "%2522%2520before%253A%2522" + getDate(1) + "%2522";
        }
      }
    },
    'it-IT': {
      ...DefaultDOIDomainConfig['it-IT'],
      gmail: {
        ...DefaultDOIDomainConfig['it-IT']['gmail'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://mail.google.com/mail/u/0/#search/from%3A" + SENDER['it-IT'] + "+after%3A" + getDate(-1) + "+in%3Aanywhere";
        }
      },
      libero: {
        ...DefaultDOIDomainConfig['it-IT']['libero'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://login.libero.it";
        }
      },
      livemail: {
        ...DefaultDOIDomainConfig['it-IT']['livemail'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://login.live.com";
        }
      },
      yahoo: {
        ...DefaultDOIDomainConfig['it-IT']['yahoo'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://login.yahoo.com";
        }
      },
      alice: {
        ...DefaultDOIDomainConfig['it-IT']['alice'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://mail.tim.it/";
        }
      },
      tiscali: {
        ...DefaultDOIDomainConfig['it-IT']['tiscali'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://mail.tiscali.it/";
        }
      },
      virgilio: {
        ...DefaultDOIDomainConfig['it-IT']['virgilio'],
        /**
         * @param {import('@wkda/internal-types').ConsumerCarlead | undefined} [carlead]
         */
        filterUrlFn: () => {
          return "https://mail.virgilio.it";
        }
      }
    }
  };
  return CustomDOIDomainConfig;
}